import { tr } from 'date-fns/locale';
import moment from 'moment';
import Image from 'next/image';
import Link from 'next/link';
import React, { useEffect, useState, Fragment } from 'react';
import SVG from 'react-inlinesvg';
import Swal from 'sweetalert2';
import Button from '../../Button';
import { createBid, updateBid } from '../../../lib/User';
import Input from '../../FormsElements/Input';
import UserTooltipGems from '../UserTooltipGems';
import styles from './styles.module.scss';
import { Menu, Transition } from '@headlessui/react';
import SharingData from '../../Card/SharingData';
import { shareOnPinterest } from '../../../lib/NFT';
import {levelColor} from '../../../Components/LevelColor'
import { useMediaQuery } from "../../../Hooks/useMediaQuery";

const SponsorSpotLightCardExplore = (props) => {
  const [selectNftBit, setSelectNftBit] = useState(false)
  const [showNft, setShowNft] = useState(false);
  const [showTimer, setShowTimer] = useState(false);
  const [showNFTTimer, setShowNFTTimer] = useState(false);
  const [placeBidBtn, setPlaceBidBtn] = useState(false);
  const [editBid, setEditBid] = useState(false);
  const [finalEditBid, setFinalEditBid] = useState(false);
  const [updateBtn, setUpdateBtn] = useState(false);
  const [mediaUrl, setMediaUrl] = useState()
  const [showMenu, setShowMenu] = useState(false)
  const [loader, setLoader] = useState(false)
  const mobileScreen = useMediaQuery('(max-width:1023px)');

  const timeValues =
    props?.timeString && props?.timeString !== ''
      ? props?.timeString.split(':')
      : ['00', '00', '00', '00'];


  const placeBidFirstFun = async (data) => {
    let lastPlacedNFT =
      props?.lastPlacedBidNft && props?.lastPlacedBidNft[data?.position]
        ? props?.lastPlacedBidNft[data?.position]
        : 0;

    let placedBidValue = document.getElementById(
      `bid_input_${data.page_type}_${data.position}`
    ).value;
    placedBidValue =
      placedBidValue && placedBidValue !== '' ? placedBidValue : 0;
    let currentBid = data.bid;
    let isGemsAvailable =
      parseInt(props?.gems) > parseInt(placedBidValue) ? 1 : 2;
    if (parseInt(currentBid) >= parseInt(placedBidValue)) {
      Swal.fire({
        icon: 'error',
        text: `Bid value should be greater than current bid value`,
        timer: 1500,
      });
    } else if (parseInt(isGemsAvailable) === 2) {
      Swal.fire({
        icon: 'error',
        text: `You have insufficient gems for bid !!!`,
        timer: 1500,
      });
    } else {
      setPlaceBidBtn(true)
    }
  };


  const placeBid = async (data) => {
    let placedBidValue/*  = document.getElementById(
      `bid_input_${data.page_type}_${data.position}`
    ).value; */
    placedBidValue =
      data.bidArr && data.bidArr[data.position] !== '' ? data.bidArr[data.position] : 0;

    let placeBidData = {
      bid_id: data.id,
      nft_id: data.selectedNftId,
      page_type: 1,
      position: data?.position,
      bid: placedBidValue,
      userID: data?.userProfileData?.id,
    };
    let currentBid = data.bid;
    let isGemsAvailable =
      parseInt(props?.gems) > parseInt(placedBidValue) ? 1 : 2;
    if (parseInt(currentBid) >= parseInt(placedBidValue)) {
      Swal.fire({
        icon: 'error',
        text: `Bid value should be greater than current bid value`,
        timer: 1500,
      });
    } else if (parseInt(isGemsAvailable) === 2) {
      Swal.fire({
        icon: 'error',
        text: `You have insufficient gems for bid !!!`,
        timer: 1500,
      });
    } else {
      const response = await createBid(placeBidData);
      if (response.data.status === 1) {
        Swal.fire({
          icon: 'success',
          text: `Bid placed successfully`,
          timer: 1500,
        });
        props?.setGems(response.data.data.gems);
        props?.getAndUpdateUserData();
        props?.getCurrentBidDataFunction();
        props.setBidArr(null)
        setPlaceBidBtn(false)
      } else {
        Swal.fire({
          icon: 'error',
          text: response?.data?.message
            ? response?.data?.message
            : `Something Went Wrong!!! Please try again after some time.`,
          timer: 1500,
        });
      }
      if (props?.socketConnection) {
        props?.socketConnection.emit(
          'latest_explore_page_data',
          props.page_type,
          props?.userState?.userProfileData?.id,
          props?.bid_page_view,
          1
        );
      }
    }
  };

  const updateBidFun = async (data) => {
    let lastPlacedBidNFT = data?.lastPlacedBidNft && data?.lastPlacedBidNft[data?.position]
      ? data?.lastPlacedBidNft[data?.position]
      : 0;

    let lastPlacedBidId =
      data?.lastPlacedBidId && data?.lastPlacedBidId[data?.position]
        ? data?.lastPlacedBidId[data?.position]
        : 0;

    let placedBidValue =
      data?.lastPlacedBid && data?.lastPlacedBid[data?.position]
        ? data?.lastPlacedBid[data?.position]
        : 0;
    let placeBidData = {
      request_id: lastPlacedBidId,
      bidId: data.id,
      nft_id: typeof data.selectedNftId === 'object' && data.selectedNftId.length > 0 ? data.selectedNftId : typeof data.selectedNftId === 'number' && data.selectedNftId !== '' ? data.selectedNftId : lastPlacedBidNFT,
      bid: data.bidArr && data.bidArr.length > 0 ? data.bidArr[data.position] : placedBidValue
    };

    let createBidData = {
      bid_id: data.id,
      nft_id: typeof data.selectedNftId === 'object' && data.selectedNftId.length > 0 ? data.selectedNftId : typeof data.selectedNftId === 'number' && data.selectedNftId !== '' ? data.selectedNftId : lastPlacedBidNFT,
      page_type: 1,
      position: data?.position,
      bid: data.bidArr && data.bidArr.length > 0 ? data.bidArr[data.position] : placedBidValue,
      userID: data?.userProfileData?.id,
    }

    let currentBid = data.bid;
    let isGemsAvailable =
      parseInt(props?.gems) > parseInt(placeBidData.bid) ? 1 : 2;

      if (((props?.user !== null && props?.user?.id) === (props?.userState?.userProfileData?.id)) && (parseInt(currentBid) !== parseInt(placeBidData.bid))) {
        if (parseInt(currentBid) > parseInt(placeBidData.bid)) {
          Swal.fire({
            icon: 'error',
            text: `Bid value should be greater than current bid value`,
            timer: 1500,
          });
        } else if (parseInt(isGemsAvailable) === 2) {
          Swal.fire({
            icon: 'error',
            text: `You have insufficient gems for bid !!!`,
            timer: 1500,
          });
        } else if (parseInt(currentBid) < parseInt(placeBidData.bid)) {
          const response = await createBid(createBidData);
          if (response.data.status === 1) {
            Swal.fire({
              icon: 'success',
              text: `Bid updated successfully`,
              timer: 1500,
            });
            props?.setGems(response.data.data.gems);
            props?.getAndUpdateUserData();
            props?.getCurrentBidDataFunction();
            props.setBidArr(null)
            setPlaceBidBtn(false)
            setUpdateBtn(false)
            setFinalEditBid(false)
            setPlaceBidBtn(false)
          } else {
            Swal.fire({
              icon: 'error',
              text: response?.data?.message
                ? response?.data?.message
                : `Something Went Wrong!!! Please try again after some time.`,
              timer: 1500,
            });
          }
          if (props?.socketConnection) {
            props?.socketConnection.emit(
              'latest_explore_page_data',
              props.page_type,
              props?.userState?.userProfileData?.id,
              props?.bid_page_view,
              props?.is_main_page
            );
          }
        }
      }
      else {
        if (parseInt(currentBid) >= parseInt(placeBidData.bid) && (props?.user !== null && props?.user?.id !== props?.userState?.userProfileData?.id)) {
          Swal.fire({
            icon: 'error',
            text: `Bid value should be greater than current bid value`,
            timer: 1500,
          });
        } else if (parseInt(isGemsAvailable) === 2) {
          Swal.fire({
            icon: 'error',
            text: `You have insufficient gems for bid !!!`,
            timer: 1500,
          });
        } else {
          let response = ''
          if (parseInt(currentBid) !== parseInt(placeBidData.bid)) {
            response = await createBid(createBidData);
          } else {
            response = await updateBid(placeBidData);
          }
          if (response.data.status === 1) {
            Swal.fire({
              icon: 'success',
              text: `Bid update successfully`,
              timer: 1500,
            });
            // props?.setGems(response.data.data.gems);
            props?.getAndUpdateUserData();
            props?.getCurrentBidDataFunction();
            props.setBidArr([])
            setPlaceBidBtn(false)
            setUpdateBtn(false)
            setFinalEditBid(false)
          } else {
            Swal.fire({
              icon: 'error',
              text: response?.data?.message
                ? response?.data?.message
                : `Something Went Wrong!!! Please try again after some time.`,
              timer: 1500,
            });
          }
          if (props?.socketConnection) {
            props?.socketConnection.emit(
              'latest_explore_page_data',
              props.page_type,
              props?.userState?.userProfileData?.id,
              props?.bid_page_view,
              props?.is_main_page
            );
          }
        }
      }
  }

  useEffect(() => {
    if (props && props?.end_time) {
      let currentDateTime = moment(new Date()).format('x');
      let endTime = props?.end_time ? props?.end_time : 0;
      if (
        parseInt(currentDateTime) > parseInt(endTime) &&
        props?.nft &&
        props?.nft?.id &&
        props?.user &&
        props?.user?.id
      ) {
        setShowNft(true);
      }
      if (parseInt(currentDateTime) < parseInt(endTime)) {
        setShowTimer(true);
      }
    }
  }, [props]);

  const onShareFunction = async(data) => {
    setMediaUrl()
    setLoader(true)
    let url = ''
    if (data?.nft?.asset?.file_type?.includes('video/')) {
        url = data?.nft?.thumbnail
        ? data?.nft?.thumbnail?.home_page_listings
        : '/assets/img/covers/cover1.jpg'
    } else if (data?.nft?.metadata?.category === 'Music') {
      url = data?.nft?.thumbnail
      ? data?.nft?.thumbnail?.home_page_listings
      : '/assets/img/image-loader.gif'
    } else {
      url = data.nft?.asset
      ? data.nft.cardSize === 'sm'
        ? data.nft.metadata?.is_collection === 0
          ? data.nft.asset.src?.home_page_listings
          : data.nft.thumbnail?.home_page_listings
        : data.nft.metadata?.is_collection === 0
        ? data.nft.asset.src?.home_page_listings
        : data.nft.thumbnail?.home_page_listings
      : '/assets/img/image-loader.gif'
    }
    const shareData = {
      is_sponsored: true,
      buttonText: data?.user?.id === data?.userState?.userProfileData?.id ? 'View' : 'Purchase',
      price: data?.nft?.metadata?.price,
      currency: data?.nft?.currency,
      level: data?.user?.level,
      user_img: data?.user?.image ? data?.user?.image : 'https://d2okfe1b01fuq1.cloudfront.net/profile/profile-274962800716254776.jpg',
      url: url,
      title:data?.nft?.title
    }
    const response = await shareOnPinterest(shareData)
    if (response?.status === 200) {
      setLoader(false)
      setMediaUrl(response?.data?.data?.path)
    } else {
      setLoader(false)
      setMediaUrl()
    }
  }

  return (
    <>
      {showNft && !showTimer && !props.bidPage && (
        <div className={`${styles.sponsor_card}`} onMouseEnter={() => {setShowMenu(true)}} onMouseLeave={() => {setShowMenu(false)}}>
          <div className={`${styles.sponsor_img_separate}`}>
            {props?.nft?.asset?.file_type?.includes('video/') ? (
                <Image
                  alt={`${props.nft ? props?.nft?.asset?.alt_text : ''}`}
                  src={`${props?.nft?.thumbnail
                    ? props?.nft?.thumbnail?.home_page_listings
                    : '/assets/img/covers/cover1.jpg'
                    }`}
                    height={378}
                    width={400}
                  objectFit="cover"
                  objectPosition="center center"
                  className={`${styles.radius_img}`}
                  onClick={() => {
                    props?.rediectToNFTDetails(props?.nft);
                  }}
                />
            ) : props?.nft?.metadata?.category === 'Music' ? (
                <Image
                  alt={`${props.nft ? props?.nft?.asset?.alt_text : ''}`}
                  src={`${props?.nft?.thumbnail
                    ? props?.nft?.thumbnail?.home_page_listings
                    : '/assets/img/image-loader.gif'
                    }`}
                    height={378}
                    width={400}
                  objectFit="cover"
                  objectPosition="center center"
                  className={`${styles.radius_img}`}
                  onClick={() => {
                    props?.rediectToNFTDetails(props?.nft);
                  }}
                />
            ) :
                <Image
                  alt="Image"
                  src={props.nft?.asset
                    ? props.nft.cardSize === 'sm'
                      ? props.nft.metadata?.is_collection === 0
                        ? props.nft.asset.src?.home_page_listings
                        : props.nft.thumbnail?.home_page_listings
                      : props.nft.metadata?.is_collection === 0
                      ? props.nft.asset.src?.home_page_listings
                      : props.nft.thumbnail?.home_page_listings
                    : '/assets/img/image-loader.gif'}
                    height={378}
                    width={400}
                  objectFit="cover"
                  objectPosition="center center"
                  className={`${styles.radius_img}`}
                  onClick={() => {
                    props?.rediectToNFTDetails(props?.nft);
                  }}
                />
            }
          </div>
          <div className={`${styles.sponsor_separate}`}>
            <Link href={`/profile/${props?.user?.public_address}`}>
            <div className="tooltip_user_section">
              <div className={`${styles.bg_profile}`}>
                <SVG
                  src="/assets/social/profile_bg.svg"
                  color={(props?.user?.level > 10 ? '#D7B49B' : levelColor(props?.user?.level))}
                />

                <div className={`${styles.user_img}`}>
                  <img src={props?.user?.image ? props?.user?.image : 'https://d2okfe1b01fuq1.cloudfront.net/profile/profile-274962800716254776.jpg'} alt="" />
                </div>

                <div className={`${styles.level_img}`}>
                  <SVG src="/assets/social/leval_bg.svg" />
                  <span
                    className={`${styles.level_text}`}
                  >{`Level ${props?.user?.level}`}</span>
                </div>
              </div>
              <UserTooltipGems
                created={
                  props?.nft?.created_by?.id === props?.nft?.owned_by?.id
                  ? true
                  : false
                }
                userData={props?.user}
                />
            </div>
                </Link>
                {/* (showMenu || mobileScreen) && */
                <Menu as="div" className={styles.card_share} >
          <Menu.Button className={styles.share_icons}>
            <Image
              src="/assets/share.svg"
              width="16px"
              height="16px"
              className={`${styles.catagorey}`}
              alt={props.metadata?.category}
              onClick={() => setMediaUrl()}
            />
          </Menu.Button>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className={`${styles.sharedetails_popup}`}>
              <SharingData mediaUrl={mediaUrl} onShareFunction={() => onShareFunction(props)} props={props} loader={loader} normalUrl={props?.nft?.linkURL} copyUrl={props?.nft?.linkURL}/>
            </Menu.Items>
          </Transition>
        </Menu>
}
            <div className={`${styles.sponsor_details_separate}`}>
              <h2 className={`${styles.sponsor_username}`} onClick={() => {
                    props?.rediectToNFTDetails(props?.nft);
                  }}>
              {props?.nft?.title
                  ? props?.nft?.title
                  : props?.nft?.title}
              </h2>
              <div className={`${styles.sponsor_btn_separate}`}>
                <p className={`${styles.sponsor_value}`}>
                  {props?.nft?.metadata?.price}{' '}
                  <span className={`${styles.sponsor_coin}`}>
                    {props?.nft?.currency}
                  </span>
                </p>
                {props?.user?.id === props?.userState?.userProfileData?.id && (
                  <Button
                    varient={'primary'}
                    className={`${styles.sponsor_btn}`}
                    onClick={() => {
                      props?.rediectToNFTDetails(props?.nft);
                    }}
                  >
                    View
                  </Button>
                )}
                {props?.user?.id !== props?.userState?.userProfileData?.id && (
                  <Button
                    varient={'primary'}
                    className={`${styles.sponsor_btn}`}
                    onClick={
                      !props?.authState?.isAuthenticated
                        ? () => props?.showConnectWalletPopup()
                        : () => {
                          props?.onPurchase(props?.nft);
                        }
                    }
                  >
                    Purchase
                  </Button>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      {showTimer && !showNft && !props.bidPage && (
        <div className={`${styles.spotlight_card}`}>
          <div className={`${styles.spotlight_separate}`}>
            <div className={`${styles.spotlight_gems_title_separates}`}>
              <h2 className={`${styles.spotlight_gemstitles}`}>
              {props?.nft && props?.nft !== '' ? 'Current Bid -' : 'Start Bid Price - '}
                <span className={`${styles.spotlight_gemsvalues}`}>
                  {props?.bid}
                </span>
              </h2>
              <div className={`${styles.spotlight_gems_small_img}`}>
                <Image src="/assets/gemssimple.svg" alt="Logo" layout="fill" />
              </div>
            </div>
            {props?.user && (
              <div className={`${styles.spotlight_details_user}`}>
                <p className={`${styles.spotlight_placed}`}>
                  Last bid Placed by
                </p>
                <h2 className={`${styles.spotlight_username}`}>
                  {props?.user?.username
                    ? props?.user?.username
                    : props?.user?.public_address.substring(0, 10)}
                </h2>
              </div>
            )}

          {props?.nft && props?.nft !== '' ? <Link href="javascript:void(0)">
              <a
                className={`${styles.spotlight_history_link}`}
                onClick={() => props?.getBidHistoryFunction(props)}
              >
                View History
              </a>
            </Link>
             : <div>&nbsp;</div>}
            {!props?.user && <div>&nbsp;</div>}
          </div>
          {!placeBidBtn &&
            <>
              <div className={`${(!placeBidBtn && !updateBtn) ? styles.spotlight_edit_box : styles.spotlight_edit_box_final}`}>
                {props?.lastPlacedBidNft && props?.lastPlacedBidNft[props?.position] && props?.lastPlacedBidNft[props?.position] !== 0 ?
                  <>
                    {/* <div className={`${styles.spotlight_input_separate}`}> */}
                    {finalEditBid ?
                      <div className={`${styles.spotlight_input_separate_update}`}>
                        <div className={`${styles.spotlight_textbox}`}>
                          <Input
                            type={'text'}
                            placeHolder={`Enter more than current bid`}
                            id={`bid_input_${props?.page_type}_${props?.position}`}
                            className={`${styles.spotlight_inputbox}`}
                            value={props?.bidArr?.length > 0 ? props.bidArr[props?.position] : props?.lastPlacedBid && props?.lastPlacedBid[props.position]}
                            onChange={(e) => props.onChangeBidValue(e, props?.position)}
                          />
                          <div className={`${styles.spotlight_gems_input_img}`}>
                            <Image
                              src="/assets/gemssimple.svg"
                              alt="Logo"
                              layout="fill"
                            />
                          </div>
                        </div>
                      </div>
                      :
                      // <div className={`${styles.spotlight_input_separate}`}>
                      <div className={`${styles.spotlight_details}`}>
                        <div className={`${styles.spotlight_bid_separate}`}>
                          <p className={`${styles.spotlight_placed}`}>
                            Your Bid is
                          </p>
                          <h2 className={`${styles.spotlight_username}`}>
                            {props?.lastPlacedBid && props?.lastPlacedBid[props.position]}
                          </h2>
                          <div className={`${styles.spotlight_gems_small_img}`}>
                            <Image src="/assets/gemssimple.svg" alt="Logo" layout="fill" />
                          </div>
                        </div>
                        <Button
                          onClick={() => setFinalEditBid(true)}
                          varient={'primary_outline'}
                        >
                          Edit Bid
                        </Button>
                      </div>
                      // </div>
                    }
                    {/* </div> */}

                    <div className={`${styles.spotlight_img_separate}`}>
                      <div className={`${styles.spotlight_edit_img}`}>
                      {props?.selectedNFT?.length > 0 && props.selectedNFT[props?.position] !== undefined ? 
                        <Image
                          alt="Image"
                          src={props?.selectedNFT?.length > 0 && props.selectedNFT[props?.position] !== undefined && props.selectedNFT[props?.position]}
                          height={finalEditBid && updateBtn ? "117px" : finalEditBid ? "139px" : updateBtn ? "143px" : "70px"}
                          width={finalEditBid && updateBtn ? "127px" : finalEditBid ? "139px" : updateBtn ? "143px" : "80px"}
                          objectFit="cover"
                          objectPosition="center center"
                          className={`${styles.sponsor_img}`}
                          onClick={() => {
                            props?.rediectToNFTDetails(props?.nft);
                          }}
                        />
                        : props?.lastPlacedBidNftImg && props?.lastPlacedBidNftImg[props?.position]?.asset?.file_type?.includes('video/') ? (
                          <Image
                          alt="Image"
                          src={`${props?.lastPlacedBidNftImg[props?.position]?.thumbnail
                            ? props?.lastPlacedBidNftImg[props?.position]?.thumbnail?.home_page_listings
                            : '/assets/img/covers/cover1.jpg'
                            }`}
                          height={finalEditBid && updateBtn ? "117px" : finalEditBid ? "139px" : updateBtn ? "143px" : "70px"}
                          width={finalEditBid && updateBtn ? "127px" : finalEditBid ? "139px" : updateBtn ? "143px" : "80px"}
                          objectFit="cover"
                          objectPosition="center center"
                          className={`${styles.sponsor_img}`}
                          onClick={() => {
                            props?.rediectToNFTDetails(props?.nft);
                          }}
                        />
                          )
                          : props?.lastPlacedBidNftImg && props?.lastPlacedBidNftImg[props?.position]?.asset?.file_type?.includes('audio/') ? (
                            <Image
                            alt="Image"
                            src={`${props?.lastPlacedBidNftImg[props?.position]?.thumbnail
                              ? props?.lastPlacedBidNftImg[props?.position]?.thumbnail?.home_page_listings
                              : '/assets/img/covers/cover1.jpg'
                              }`}
                            height={finalEditBid && updateBtn ? "117px" : finalEditBid ? "139px" : updateBtn ? "143px" : "70px"}
                            width={finalEditBid && updateBtn ? "127px" : finalEditBid ? "139px" : updateBtn ? "143px" : "80px"}
                            objectFit="cover"
                            objectPosition="center center"
                            className={`${styles.sponsor_img}`}
                            onClick={() => {
                              props?.rediectToNFTDetails(props?.nft);
                            }}
                          />
                            )
                            : 
                            <Image
                            alt="Image"
                            src={`${props?.lastPlacedBidNftImg && props?.lastPlacedBidNftImg[props?.position]?.asset
                              ? props?.lastPlacedBidNftImg[props?.position].cardSize === 'sm'
                                ? props?.lastPlacedBidNftImg[props?.position].metadata?.is_collection === 0
                                  ? props?.lastPlacedBidNftImg[props?.position].asset.src?.home_page_listings
                                  : props?.lastPlacedBidNftImg[props?.position].thumbnail?.home_page_listings
                                : props?.lastPlacedBidNftImg[props?.position].metadata?.is_collection === 0
                                ? props?.lastPlacedBidNftImg[props?.position].asset.src?.home_page_listings
                                : props?.lastPlacedBidNftImg[props?.position].thumbnail?.home_page_listings
                              : '/assets/img/image-loader.gif'}`}
                            height={finalEditBid && updateBtn ? "117px" : finalEditBid ? "139px" : updateBtn ? "143px" : "70px"}
                            width={finalEditBid && updateBtn ? "127px" : finalEditBid ? "139px" : updateBtn ? "143px" : "80px"}
                            objectFit="cover"
                            objectPosition="center center"
                            className={`${styles.sponsor_img}`}
                            onClick={() => {
                              props?.rediectToNFTDetails(props?.nft);
                            }}
                          />
                          }
                        <div className={`${styles.spotlight_editicon_img}`} onClick={() => {
                          props?.selectNFT(props);
                          setUpdateBtn(true)
                        }
                        }
                        >
                          <Image
                            alt="Image"
                            src="/assets/editicon.svg"
                            height="20px"
                            width="20px"
                          />
                        </div>
                      </div>
                    </div>
                  </>
                  :
                  <div className={`${styles.spotlight_input_separate}`}>
                    <div className={`${styles.spotlight_textbox}`}>
                      <Input
                        type={'text'}
                        placeHolder={`Enter more than current bid`}
                        id={`bid_input_${props?.page_type}_${props?.position}`}
                        className={`${styles.spotlight_inputbox}`}
                        value={props?.bidArr?.length > 0 ? props.bidArr[props?.position] : ''}
                        onChange={(e) => props.onChangeBidValue(e, props?.position)}
                      />
                      <div className={`${styles.spotlight_gems_input_img}`}>
                        <Image
                          src="/assets/gemssimple.svg"
                          alt="Logo"
                          layout="fill"
                        />
                      </div>
                    </div>
                    {!finalEditBid &&
                      <Button
                        onClick={
                          !props?.authState?.isAuthenticated
                            ? () => props?.showConnectWalletPopup()
                            : () => { placeBidFirstFun(props) }
                        }
                        varient={'primary'}
                      >
                        Place Bid
                      </Button>
                    }
                  </div>
                }
                {(finalEditBid || updateBtn) &&
                  <div className={`${styles.spotlight_update_button}`}>
                    <Button
                      onClick={
                        !props?.authState?.isAuthenticated
                          ? () => props?.showConnectWalletPopup()
                          : () => { updateBidFun(props) }
                      }
                      varient={'primary'}
                    >
                      Update Bid
                    </Button>
                  </div>
                }
                {!finalEditBid && !updateBtn &&
                  <div className={`${styles.spotlight_time_box}`}>
                    <p className={`${styles.spotlight_time_left}`}>Time Left</p>
                    <div className={`${styles.spotlight_time_separate}`}>
                      <div className={`${styles.spotlight_time_column}`}>
                        <Input
                          type={'text'}
                          placeHolder={``}
                          value={timeValues[0]}
                          id="hours"
                          className={`${styles.time_inputbox}`}
                        />
                        <span className={`${styles.time_colun}`}>:</span>
                      </div>
                      <div className={`${styles.spotlight_time_column}`}>
                        <Input
                          type={'text'}
                          placeHolder={``}
                          value={timeValues[1]}
                          id="hours"
                          className={`${styles.time_inputbox}`}
                        />
                        <span className={`${styles.time_colun}`}>:</span>
                      </div>

                      <div className={`${styles.spotlight_time_column}`}>
                        <Input
                          type={'text'}
                          placeHolder={``}
                          value={timeValues[2]}
                          id="minutes"
                          className={`${styles.time_inputbox}`}
                        />
                        <span className={`${styles.time_colun}`}>:</span>
                      </div>

                      <div className={`${styles.spotlight_time_column}`}>
                        <Input
                          type={'text'}
                          placeHolder={``}
                          value={timeValues[3]}
                          id="seconds"
                          className={`${styles.time_inputbox}`}
                        />
                      </div>
                    </div>
                    <div className={`${styles.spotlight_date_separates}`}>
                      <p className={`${styles.spotlight_date}`}>
                        {moment(props?.end_time, 'x').format('YYYY, MMM D')}
                      </p>
                      <p className={`${styles.spotlight_date}`}>
                        {moment(props?.end_time, 'x').format('HH:mm:ss')}
                      </p>
                    </div>
                  </div>
                }
              </div>
              <div className={`${styles.spotlight_count}`}>{props?.position}</div>
            </>
          }

          {placeBidBtn &&
            <>
              {editBid ?
                <div className={`${styles.spotlight_details}`}>
                  <div className={`${styles.spotlight_textbox}`}>
                    <Input
                      type={'text'}
                      placeHolder={`Enter more than current bid`}
                      id={`bid_input_${props?.page_type}_${props?.position}`}
                      className={`${styles.spotlight_inputbox}`}
                      value={props?.bidArr?.length > 0 ? props.bidArr[props?.position] : ''}
                      onChange={(e) => props.onChangeBidValue(e, props?.position)}
                    />
                    <div className={`${styles.spotlight_gems_input_img}`}>
                      <Image
                        src="/assets/gemssimple.svg"
                        alt="Logo"
                        layout="fill"
                      />
                    </div>
                  </div>
                </div> :
                <div className={`${styles.spotlight_details}`}>
                  <div className={`${styles.spotlight_bid_separate}`}>
                    <p className={`${styles.spotlight_placed}`}>
                      Your Bid is
                    </p>
                    <h2 className={`${styles.spotlight_username}`}>
                      {props?.bidArr?.length > 0 ? props.bidArr[props?.position] : ''}

                    </h2>
                    <div className={`${styles.spotlight_gems_small_img}`}>
                      <Image src="/assets/gemssimple.svg" alt="Logo" layout="fill" />
                    </div>
                  </div>
                  <Button
                    onClick={() => setEditBid(true)}
                    varient={'primary_outline'}
                  >
                    Edit Bid
                  </Button>
                </div>
              }
              <div className={`${styles.spotlight_img_separate}`}>
                <>
                  <div className={`${styles.spotlight_edit_img}`}>
                    <Image
                      alt="Image"
                      src={props?.selectedNFT?.length > 0 && props.selectedNFT[props?.position] !== undefined ? props.selectedNFT[props?.position] : "/assets/nobidimage.png"}
                      height={editBid ? "124px" : "144px"}
                      width={editBid ? "143px" : "154px"}
                    // height="127px"
                    // width="136px"
                    />
                    {props?.selectedNFT?.length > 0 && props.selectedNFT[props?.position] &&
                      <div className={`${styles.spotlight_editicon_img}`} onClick={() => props?.selectNFT(props)}>
                        <Image
                          alt="Image"
                          src="/assets/editicon.svg"
                          height="20px"
                          width="20px"
                        />
                      </div>
                    }
                  </div>
                  {props?.selectedNFT?.length > 0 && props.selectedNFT[props?.position] ?
                    <Button
                      varient={'primary'}
                      onClick={() => placeBid(props)}
                      className="mb-2"
                    >
                      Submit
                    </Button>
                    :
                    <Button
                      varient={'primary'}
                      className="mb-2"
                      onClick={() => props?.selectNFT(props)}
                    >
                      Select NFT
                    </Button>
                  }
                </>
              </div>
            </>
          }


        </div>
      )}
      {props.bidPage && (
        <div className={`${styles.spotlight_card}`}>
          <div className={`${styles.spotlight_separate}`}>
            <div className={`${styles.spotlight_gems_title_separates}`}>
              <h2 className={`${styles.spotlight_gemstitles}`}>
              {props?.nft && props?.nft !== '' ? 'Current Bid -' : 'Start Bid Price - '}
                <span className={`${styles.spotlight_gemsvalues}`}>
                  {props?.bid}
                </span>
              </h2>
              <div className={`${styles.spotlight_gems_small_img}`}>
                <Image src="/assets/gemssimple.svg" alt="Logo" layout="fill" />
              </div>
            </div>
            {props?.user && (
              <div className={`${styles.spotlight_details_user}`}>
                <p className={`${styles.spotlight_placed}`}>
                  Last bid Placed by
                </p>
                <h2 className={`${styles.spotlight_username}`}>
                  {props?.user?.username
                    ? props?.user?.username
                    : props?.user?.public_address.substring(0, 10)}
                </h2>
              </div>
            )}

          {props?.nft && props?.nft !== '' ? <Link href="javascript:void(0)">
              <a
                className={`${styles.spotlight_history_link}`}
                onClick={() => props?.getBidHistoryFunction(props)}
              >
                View History
              </a>
            </Link>
             : <div>&nbsp;</div>}
            {!props?.user && <div>&nbsp;</div>}
          </div>
          {!placeBidBtn &&
            <>
              <div className={`${(!placeBidBtn && !updateBtn) ? styles.spotlight_edit_box : styles.spotlight_edit_box_final}`}>
                {props?.lastPlacedBidNft && props?.lastPlacedBidNft[props?.position] && props?.lastPlacedBidNft[props?.position] !== 0 ?
                  <>
                    {/* <div className={`${styles.spotlight_input_separate}`}> */}
                    {finalEditBid ?
                      <div className={`${styles.spotlight_input_separate_update}`}>
                        <div className={`${styles.spotlight_textbox}`}>
                          <Input
                            type={'text'}
                            placeHolder={`Enter more than current bid`}
                            id={`bid_input_${props?.page_type}_${props?.position}`}
                            className={`${styles.spotlight_inputbox}`}
                            value={props?.bidArr?.length > 0 ? props.bidArr[props?.position] : props?.lastPlacedBid && props?.lastPlacedBid[props.position]}
                            onChange={(e) => props.onChangeBidValue(e, props?.position)}
                          />
                          <div className={`${styles.spotlight_gems_input_img}`}>
                            <Image
                              src="/assets/gemssimple.svg"
                              alt="Logo"
                              layout="fill"
                            />
                          </div>
                        </div>
                      </div>
                      :
                      // <div className={`${styles.spotlight_input_separate}`}>
                      <div className={`${styles.spotlight_details}`}>
                        <div className={`${styles.spotlight_bid_separate}`}>
                          <p className={`${styles.spotlight_placed}`}>
                            Your Bid is
                          </p>
                          <h2 className={`${styles.spotlight_username}`}>
                            {props?.lastPlacedBid && props?.lastPlacedBid[props.position]}
                          </h2>
                          <div className={`${styles.spotlight_gems_small_img}`}>
                            <Image src="/assets/gemssimple.svg" alt="Logo" layout="fill" />
                          </div>
                        </div>
                        <Button
                          onClick={() => setFinalEditBid(true)}
                          varient={'primary_outline'}
                        >
                          Edit Bid
                        </Button>
                      </div>
                      // </div>
                    }
                    {/* </div> */}

                    <div className={`${styles.spotlight_img_separate}`}>
                      <div className={`${styles.spotlight_edit_img}`}>
                      {props?.selectedNFT?.length > 0 && props.selectedNFT[props?.position] !== undefined ? 
                        <Image
                          alt="Image"
                          src={props?.selectedNFT?.length > 0 && props.selectedNFT[props?.position] !== undefined && props.selectedNFT[props?.position]}
                          height={finalEditBid && updateBtn ? "123px" : finalEditBid ? "143px" : updateBtn ? "143px" : "74px"}
                          width={finalEditBid && updateBtn ? "133px" : finalEditBid ? "152px" : updateBtn ? "143px" : "80px"}
                          objectFit="cover"
                          objectPosition="center center"
                          className={`${styles.sponsor_img}`}
                          onClick={() => {
                            props?.rediectToNFTDetails(props?.nft);
                          }}
                        />
                        : props?.lastPlacedBidNftImg && props?.lastPlacedBidNftImg[props?.position]?.asset?.file_type?.includes('video/') ? (
                          <Image
                          alt="Image"
                          src={`${props?.lastPlacedBidNftImg[props?.position]?.thumbnail
                            ? props?.lastPlacedBidNftImg[props?.position]?.thumbnail?.home_page_listings
                            : '/assets/img/covers/cover1.jpg'
                            }`}
                          height={finalEditBid && updateBtn ? "123px" : finalEditBid ? "143px" : updateBtn ? "143px" : "74px"}
                          width={finalEditBid && updateBtn ? "133px" : finalEditBid ? "152px" : updateBtn ? "143px" : "80px"}
                          objectFit="cover"
                          objectPosition="center center"
                          className={`${styles.sponsor_img}`}
                          onClick={() => {
                            props?.rediectToNFTDetails(props?.nft);
                          }}
                        />
                        )
                        : props?.lastPlacedBidNftImg && props?.lastPlacedBidNftImg[props?.position]?.asset?.file_type?.includes('audio/') ? (
                          <Image
                          alt="Image"
                          src={`${props?.lastPlacedBidNftImg[props?.position]?.thumbnail
                            ? props?.lastPlacedBidNftImg[props?.position]?.thumbnail?.home_page_listings
                            : '/assets/img/covers/cover1.jpg'
                            }`}
                          height={finalEditBid && updateBtn ? "123px" : finalEditBid ? "143px" : updateBtn ? "143px" : "74px"}
                          width={finalEditBid && updateBtn ? "133px" : finalEditBid ? "152px" : updateBtn ? "143px" : "80px"}
                          objectFit="cover"
                          objectPosition="center center"
                          className={`${styles.sponsor_img}`}
                          onClick={() => {
                            props?.rediectToNFTDetails(props?.nft);
                          }}
                        />
                        )
                          : 
                          <Image
                          alt="Image"
                          src={`${props?.lastPlacedBidNftImg && props?.lastPlacedBidNftImg[props?.position]?.asset
                            ? props?.lastPlacedBidNftImg[props?.position].cardSize === 'sm'
                              ? props?.lastPlacedBidNftImg[props?.position].metadata?.is_collection === 0
                                ? props?.lastPlacedBidNftImg[props?.position].asset.src?.home_page_listings
                                : props?.lastPlacedBidNftImg[props?.position].thumbnail?.home_page_listings
                              : props?.lastPlacedBidNftImg[props?.position].metadata?.is_collection === 0
                              ? props?.lastPlacedBidNftImg[props?.position].asset.src?.home_page_listings
                              : props?.lastPlacedBidNftImg[props?.position].thumbnail?.home_page_listings
                            : '/assets/img/image-loader.gif'}`}
                          height={finalEditBid && updateBtn ? "123px" : finalEditBid ? "143px" : updateBtn ? "143px" : "74px"}
                          width={finalEditBid && updateBtn ? "133px" : finalEditBid ? "152px" : updateBtn ? "143px" : "80px"}
                          objectFit="cover"
                          objectPosition="center center"
                          className={`${styles.sponsor_img}`}
                          onClick={() => {
                            props?.rediectToNFTDetails(props?.nft);
                          }}
                        />
                      }
                        <div className={`${styles.spotlight_editicon_img}`} onClick={() => {
                          props?.selectNFT(props);
                          setUpdateBtn(true)
                        }
                        }
                        >
                          <Image
                            alt="Image"
                            src="/assets/editicon.svg"
                            height="20px"
                            width="20px"
                          />
                        </div>
                      </div>
                    </div>
                  </>
                  :
                  <div className={`${styles.spotlight_input_separate}`}>
                    <div className={`${styles.spotlight_textbox}`}>
                      <Input
                        type={'text'}
                        placeHolder={`Enter more than current bid`}
                        id={`bid_input_${props?.page_type}_${props?.position}`}
                        className={`${styles.spotlight_inputbox}`}
                        value={props?.bidArr?.length > 0 ? props.bidArr[props?.position] : ''}
                        onChange={(e) => props.onChangeBidValue(e, props?.position)}
                      />
                      <div className={`${styles.spotlight_gems_input_img}`}>
                        <Image
                          src="/assets/gemssimple.svg"
                          alt="Logo"
                          layout="fill"
                        />
                      </div>
                    </div>
                    {!finalEditBid &&
                      <Button
                        onClick={
                          !props?.authState?.isAuthenticated
                            ? () => props?.showConnectWalletPopup()
                            : () => { placeBidFirstFun(props) }
                        }
                        varient={'primary'}
                      >
                        Place Bid
                      </Button>
                    }
                  </div>
                }
                {(finalEditBid || updateBtn) &&
                  <div className={`${styles.spotlight_update_button}`}>
                    <Button
                      onClick={
                        !props?.authState?.isAuthenticated
                          ? () => props?.showConnectWalletPopup()
                          : () => { updateBidFun(props) }
                      }
                      varient={'primary'}
                    >
                      Update Bid
                    </Button>
                  </div>
                }
                {!finalEditBid && !updateBtn &&
                  <div className={`${styles.spotlight_time_box}`}>
                    <p className={`${styles.spotlight_time_left}`}>Time Left</p>
                    <div className={`${styles.spotlight_time_separate}`}>
                      <div className={`${styles.spotlight_time_column}`}>
                        <Input
                          type={'text'}
                          placeHolder={``}
                          value={timeValues[0]}
                          id="hours"
                          className={`${styles.time_inputbox}`}
                        />
                        <span className={`${styles.time_colun}`}>:</span>
                      </div>
                      <div className={`${styles.spotlight_time_column}`}>
                        <Input
                          type={'text'}
                          placeHolder={``}
                          value={timeValues[1]}
                          id="hours"
                          className={`${styles.time_inputbox}`}
                        />
                        <span className={`${styles.time_colun}`}>:</span>
                      </div>

                      <div className={`${styles.spotlight_time_column}`}>
                        <Input
                          type={'text'}
                          placeHolder={``}
                          value={timeValues[2]}
                          id="minutes"
                          className={`${styles.time_inputbox}`}
                        />
                        <span className={`${styles.time_colun}`}>:</span>
                      </div>

                      <div className={`${styles.spotlight_time_column}`}>
                        <Input
                          type={'text'}
                          placeHolder={``}
                          value={timeValues[3]}
                          id="seconds"
                          className={`${styles.time_inputbox}`}
                        />
                      </div>
                    </div>
                    <div className={`${styles.spotlight_date_separates}`}>
                      <p className={`${styles.spotlight_date}`}>
                        {moment(props?.end_time, 'x').format('YYYY, MMM D')}
                      </p>
                      <p className={`${styles.spotlight_date}`}>
                        {moment(props?.end_time, 'x').format('HH:mm:ss')}
                      </p>
                    </div>
                  </div>
                }
              </div>
              <div className={`${styles.spotlight_count}`}>{props?.position}</div>
            </>
          }

          {placeBidBtn &&
            <>
              {editBid ?
                <div className={`${styles.spotlight_details}`}>
                  <div className={`${styles.spotlight_textbox}`}>
                    <Input
                      type={'text'}
                      placeHolder={`Enter more than current bid`}
                      id={`bid_input_${props?.page_type}_${props?.position}`}
                      className={`${styles.spotlight_inputbox}`}
                      value={props?.bidArr?.length > 0 ? props.bidArr[props?.position] : ''}
                      onChange={(e) => props.onChangeBidValue(e, props?.position)}
                    />
                    <div className={`${styles.spotlight_gems_input_img}`}>
                      <Image
                        src="/assets/gemssimple.svg"
                        alt="Logo"
                        layout="fill"
                      />
                    </div>
                  </div>
                </div> :
                <div className={`${styles.spotlight_details}`}>
                  <div className={`${styles.spotlight_bid_separate}`}>
                    <p className={`${styles.spotlight_placed}`}>
                      Your Bid is
                    </p>
                    <h2 className={`${styles.spotlight_username}`}>
                      {props?.bidArr?.length > 0 ? props.bidArr[props?.position] : ''}

                    </h2>
                    <div className={`${styles.spotlight_gems_small_img}`}>
                      <Image src="/assets/gemssimple.svg" alt="Logo" layout="fill" />
                    </div>
                  </div>
                  <Button
                    onClick={() => setEditBid(true)}
                    varient={'primary_outline'}
                  >
                    Edit Bid
                  </Button>
                </div>
              }
              <div className={`${styles.spotlight_img_separate}`}>
                <>
                  <div className={`${styles.spotlight_edit_img}`}>
                    <Image
                      alt="Image"
                      src={props?.selectedNFT?.length > 0 && props.selectedNFT[props?.position] !== undefined ? props.selectedNFT[props?.position] : "/assets/nobidimage.png"}
                      height={editBid ? "127px" : "153px"}
                      width={editBid ? "136px" : "162px"}
                    // height="127px"
                    // width="136px"
                    />
                    {props?.selectedNFT?.length > 0 && props.selectedNFT[props?.position] &&
                      <div className={`${styles.spotlight_editicon_img}`} onClick={() => props?.selectNFT(props)}>
                        <Image
                          alt="Image"
                          src="/assets/editicon.svg"
                          height="20px"
                          width="20px"
                        />
                      </div>
                    }
                  </div>
                  {props?.selectedNFT?.length > 0 && props.selectedNFT[props?.position] ?
                    <Button
                      varient={'primary'}
                      onClick={() => placeBid(props)}
                      className="mb-2"
                    >
                      Submit
                    </Button>
                    :
                    <Button
                      varient={'primary'}
                      className="mb-2"
                      onClick={() => props?.selectNFT(props)}
                    >
                      Select NFT
                    </Button>
                  }
                </>
              </div>
            </>
          }


        </div>
      )}
    </>
  );
};

export default SponsorSpotLightCardExplore;
